import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS } from '@contentful/rich-text-types'

import Seo from "../components/Seo"
import PageTitle from '../components/PageTitle'
import NewsletterCTA from '../components/NewsletterCTA'

const OpeningParagraph = styled.section`
  margin-bottom: 90px;

  .container {
    max-width: 850px;
    margin: 0 auto;

    p {
      color: var(--gray);
      text-align: center;
    }
  }
`

const ServiceDetailsSection = styled.section`
  margin-bottom: 120px;

  .row-two-col {
    align-items: center;
  }

  ul {
    padding-left: 25px;

    li {
      margin-bottom: 5px;
      color: var(--gray);
  
      p {
        margin-bottom: 0;
      }
    }
  }

  p,
  ul {
    margin-bottom: 35px;
  }

  .gatsby-image-wrapper {
    position: relative;

    ::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.8;
      background-image: linear-gradient(225deg, #000000 0%, rgba(0,0,0,0.00) 47%, #000000 100%);
    }
  }
`

const ServiceResourcesSection = styled.section`
  margin-bottom: 100px;
  text-align: center;

  .why-fintech,
  .service-cta {
    width: 100%;
    max-width: 850px;
    margin: 0 auto;
    text-align: center;
    color: var(--gray);
  }

  .why-fintech {
    margin-bottom: 40px;
  }
  
  .service-cta {
    margin-bottom: 100px;
  }

  .service-lg-image {
    margin-bottom: 40px;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.8;
      background-image: linear-gradient(225deg, #000000 0%, rgba(0,0,0,0.00) 47%, #000000 100%);
    }
  }

  .service-resource-grid {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    column-gap: 70px;
    row-gap: 30px;

    .gatsby-image-wrapper {
      width: 270px;
      height: 270px;
      border: 1px solid var(--pink);
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.8;
        background-image: linear-gradient(225deg, #000000 0%, rgba(0,0,0,0.00) 47%, #000000 100%);
      }
    }

    .service-resource {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
    }

    .service-resource-label {
      background-color: var(--pink);
      color: var(--white);
      border: 2px solid var(--white);
      display: block;
      padding: 10px 25px;
      width: 110px;
      font-size: 20px;
      font-weight: var(--bold);
      text-decoration: none;
      box-sizing: border-box;
      margin: -23px auto 0 auto;
      z-index: 1;
    }
  }
`

function Service({ pageContext }) {
  const { service } = pageContext

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
      [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
      [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
    }
  };

  return (
    <main>
      <PageTitle key={service.id} title={service.pageTitle} paragraph={service.pageTitleParagraph.pageTitleParagraph} />
      {service.serviceOpeningParagraph && (
        <OpeningParagraph>
          <div className="container">
            <p>{service.serviceOpeningParagraph.serviceOpeningParagraph}</p>
          </div>
        </OpeningParagraph>
      )}
      <ServiceDetailsSection>
        <div className="container">
          <div className="row-two-col">
            <div className="col-1">
              {service.servicesH2 && (
                <h2>{service.servicesH2}</h2>
              )}
              {service.servicesOfferings && renderRichText(service.servicesOfferings, options)}
              {service.servicesButtonUrl && (
                <a href={service.servicesButtonUrl} className="btn">{service.servicesButtonText}</a>
              )}
            </div>
            <div className="col-2">
              {service.servicesImage && (
                <GatsbyImage image={service.servicesImage.gatsbyImageData} alt={service.servicesImage.title} />
              )}
            </div>
          </div>
        </div>
      </ServiceDetailsSection>
      <ServiceResourcesSection>
        {service.whyFinTech && (
          <p className="why-fintech">{service.whyFinTech.whyFinTech}</p>
        )}
        {service.servicesLargeImage && (
          <GatsbyImage className="service-lg-image" image={service.servicesLargeImage.gatsbyImageData} alt={service.servicesLargeImage.title} />
        )}
        {service.servicesBlogImage && (
          <div className="service-resource-grid">
            <div className="service-resource">
              <a href={service.servicesBlogUrl}>
                <GatsbyImage image={service.servicesBlogImage.gatsbyImageData} alt={service.servicesBlogImage.title} />
              </a>
              <a className="service-resource-label" href={service.servicesBlogUrl}>Blog</a>
            </div>
            <div className="service-resource">
              <a href={service.servicesEBookUrl} target="_blank" rel="noopener noreferrer">
                <GatsbyImage image={service.servicesEBookImage.gatsbyImageData} alt={service.servicesEBookImage.title} />
              </a>
              <a href={service.servicesEBookUrl} target="_blank" rel="noopener noreferrer" className="service-resource-label">EBook</a>
            </div>
            <div className="service-resource">
              <a href={service.servicesEventsUrl}>
                <GatsbyImage image={service.servicesEventsImage.gatsbyImageData} alt={service.servicesEventsImage.title} />
              </a>
              <a className="service-resource-label" href={service.servicesEventsUrl}>Events</a>
            </div>
          </div>
        )}
        {service.servicesCta && (
          <p className="service-cta">{service.servicesCta.servicesCta}</p>
        )}
        {service.servicesButtonUrl && (
          <a href={service.servicesButtonUrl} className="btn btn-large">{service.servicesButtonText}</a>
        )}
      </ServiceResourcesSection>
      <NewsletterCTA />
    </main>
  )
}

export default Service

export const Head = (pageContext) => {
  return (
    <>
      <Seo 
        key={pageContext.pageContext.service.id} 
        title={pageContext.pageContext.service.seoTitle}
        description={pageContext.pageContext.service.seoTitle}
        image={pageContext.pageContext.service.seoImage.url}
      />
    </>
  )
}
